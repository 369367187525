import React, { useState, useMemo } from "react";
import { Box, useTheme, Button, Typography} from "@mui/material";
import {useGetTabMutation } from "state/api";
import { useSelector } from 'react-redux';
import JsBarcode from "jsbarcode";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import DesktopDatePicker from 'react-datepicker';
import { format } from "date-fns";

const Exportcsv = () => {
  const storeId = useSelector((state) => state.global.store._id);
  // const {data, error, isSuccess}  = useGetTabQuery(storeId);
  const [getTab] = useGetTabMutation()
  // const [tabData, setTabData] = useState(null);
    const Today = new Date().toISOString().slice(0, 10);
    const [startDate, setStartDate] = useState(new Date(Today))
    const [endDate, setEndDate] = useState(new Date(Today))

  const theme = useTheme();

  const generateBarcodeImage = (ean) => {
    const canvas = document.createElement("canvas");
    
    let format = "EAN13"; 
    
    if (ean.length === 8) {
      format = "EAN8"; 
    } else if (ean.length !== 13) {
      canvas.width = 1;
      canvas.height = 1;
      return canvas.toDataURL("image/png");
    }
    
    JsBarcode(canvas, ean, { format }); 
    
    const result = canvas.toDataURL("image/png");
    return result;
  };
  
  const handleExportPDF = async () => {
    console.log("startDate:", startDate);
    console.log("endDate:", endDate);
    const start = format(startDate, "yyyy-MM-dd");
    const end = format(endDate, "yyyy-MM-dd");
    const tabData = await getTab({ storeId, start, end });


    const doc = new jsPDF();
    const startX = 10;
    const startY = 25;
    const rowHeight = 50;
    const columnWidth = 50;
    const lineOffset = 5; 
    // const headers = ['Date', 'Article', 'Quantité', 'Prix total', 'EAN'];
    const headers = ['Date', 'Quantité', 'Prix total', 'EAN'];
  
    for (let pageIndex = 0; pageIndex < Math.ceil(tabData.data.length / 5); pageIndex++) {
      if (pageIndex > 0) {
        doc.addPage();
      }
  
      // header
      for (let j = 0; j < headers.length; j++) {
        doc.setFontSize(20);
        const xPos = j === headers.indexOf('EAN')
          ? startX + j * columnWidth + columnWidth / 2 - 10 
          : startX + j * columnWidth;
        doc.text(headers[j], xPos, startY);
      }
  
      doc.setFontSize(12);
  
     
      const startIndex = pageIndex * 5;
      const endIndex = Math.min(startIndex + 5, tabData.data.length);
  
      for (let i = startIndex; i < endIndex; i++) {
        const row = tabData.data[i];
        const tableDataRow = [
          row.Date,
          // row.name,
          row.totalQty,
          `${row.totalPrice.toFixed(2)} €`,
          '' // espace vide pour rajouter img ean après
        ];
  
        for (let j = 0; j < tableDataRow.length; j++) {
          const titleXPos = j === headers.indexOf('EAN')
            ? startX + j * columnWidth + columnWidth / 2 - 10
            : startX + j * columnWidth;
  
          let valueXPos;
  
          if (j === headers.indexOf('Quantité') || j === headers.indexOf('Prix total')) {
            // Centrer les colonnes quantité et prix total
            valueXPos = titleXPos + (columnWidth / 2) - (doc.getStringUnitWidth(tableDataRow[j].toString()) * doc.internal.getFontSize() / 2);
          } else {
            valueXPos = titleXPos;
          }
  
          doc.text(tableDataRow[j].toString(), valueXPos, startY + (i % 5 + 1) * rowHeight);
        }
  
        // barcode image
        const barcodeImage = generateBarcodeImage(row.Code_Barre);
        doc.addImage(
          barcodeImage,
          'png',
          startX + headers.indexOf('EAN') * columnWidth + columnWidth / 2 - 25, 
          startY + (i % 5 + 1) * rowHeight - 10 - lineOffset, 
          40,
          15
        );
  
       // ligne horizontale à chaque ligne
        doc.setLineWidth(0.1);
        doc.line(
          startX,
          startY + (i % 5 + 1) * rowHeight + lineOffset,
          startX + headers.length * columnWidth,
          startY + (i % 5 + 1) * rowHeight + lineOffset
        );
      }
    }
  
    doc.save(`export_commandes_${start}_${end}.pdf`);
  };
  
        // const columns = useMemo(
        //   () => [
        //     {
        //       accessorKey: 'Date',
        //       header: 'Date',
        //       size: 50,
        //     },
        //     {
        //       accessorKey: '_id',
        //       header: 'Nom',
        //       size: 50,
        //     },
        //     {
        //       accessorKey: 'Code_Barre',
        //       header: 'Code_Barre',
        //       size: 50,
        //     },
        //     {
        //       accessorKey: 'totalQty',
        //       header: 'Quantité',
        //       size: 50,
        //     },
        //     {
        //       accessorKey: 'totalPrice',
        //       header: 'Prix',
        //       Cell: ({ row }) => <span>{`${row.original.totalPrice.toFixed(2)} €`}</span>,
        //     },
        //     {
        //       accessorKey: 'Code_Barre',
        //       header: 'Img',
        //       size: 50,
        //       Cell: ({ row }) => {
        //         const ean = row.original.Code_Barre;
        //         const barcodeImage = generateBarcodeImage(ean);
        //         return <img src={barcodeImage} alt={ean} />;
        //       },
        //     },
        //   ],
        //   [],
        // );

  return (
    <Box      sx={{
      textAlign: "center",
      marginTop: "2.25rem", 
    }}
    >
      <h1 style={{ color: "black", marginBottom: "1rem" }}>
        EXPORT DES COMMANDES
      </h1>
    <Box display="flex" justifyContent="center">
      <Box>
      <Typography sx={{color : 'black'}}>Date de début</Typography>
        <DesktopDatePicker sx = {{width : "5rem"}}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
        />
      </Box>
      <Box sx={{ marginBottom: "2rem", marginLeft: "2rem" }}>
        <Typography sx={{color : 'black'}}>Date de fin</Typography>
        <DesktopDatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="dd/MM/yyyy" // Format the date to display without extra white space
        />
      </Box>
    </Box>

    <Box display="flex" justifyContent="center" marginTop="1rem">
      <Button sx={{ backgroundColor: "#90DAFA" }} variant="contained" onClick={handleExportPDF}>
        Export to PDF
      </Button>
    </Box>


      {/* <Box
        height="80vh"
        sx={{
          "& .MuiPaper-root" : {
            border: '1px solid #F0EAEA',
            boxShadow : 'none'
          },
          "& .MuiToolbar-root" : {
           backgroundColor : "#ffffff",
          },
          "& .MuiTableRow-root" : {
            backgroundColor : "#ffffff"
          },
          "& .MuiTableRow-root.Mui-TableBodyCell-DetailPanel" : {
            backgroundColor : theme.palette.primary[600]
          },
          "& .MuiTableCell-root" : {
            color : theme.palette.secondary.main,
          },
          "& .MuiButtonBase-root": {
            color : theme.palette.secondary.main,
          },
          "& .MuiInputBase-input": {
            // backgroundColor : theme.palette.background.alt
            backgroundColor : "#ffffff"
          }
        }}
      >

        
      {tabData && (
  
  <MaterialReactTable
  columns={columns}
  data={tabData}
/>
)}
      </Box> */}

    </Box>
  );
};

export default Exportcsv;