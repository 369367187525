import React, { useRef, useState } from 'react';
import { usePostRegisterMutation } from '../../state/api';



const RegisterScreen = () => {


  const userRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');

  const [skip, setSkip] = useState(true);
  const [getLogin]= usePostRegisterMutation();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSkip(false);
    } catch (err) {
      console.log(err);
    }
  };


  const handleLogIn = () =>{
    getLogin(    
      { user, pwd },
      {
        skip: skip,
      })
  }

  return (
    <>

      {/* Right Block */}
      <div className="right-block">
        {/* Content for the right block */}
          <section>
            <div className="right-content">
              <label className="title">Register</label>
              <form onSubmit={handleSubmit}>
                <div className="form-item">
                  <label htmlFor="username">Username:</label>
                  <input
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                  />
                </div>

                <div className="form-item">
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                  />
                </div>
                <button onClick= {handleLogIn}className="login-button">Register</button>
              </form>
            </div>
          </section>
      </div>
    </>
  );
};

export default RegisterScreen;