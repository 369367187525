import { Box, Tab, Tabs, Typography } from '@mui/material';
import AddProduct from 'components/AddProduct';
import React, { useState } from 'react';

const AjoutProduit = () => {
    const [value, setValue] = useState(0)

    function CustomTabPanel(props) {
        const { children, value, index } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            color="black"
            style={{color:"black"}}
          >
            {value === index && (
              <Box sx={{ p: 3 , color: "black"}}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
    return (
        <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={value}
            aria-label="basic tabs example" >
                <Tab label="AJOUT PRODUIT" 
                sx={{color: "black"}}
                onClick={() => setValue(0)}
                />
                <Tab label="RECHERCHE PRODUIT"  onClick={() => setValue(1)} sx={{color: "black"}}
                />
            </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} >
            <AddProduct/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
    
    
        </CustomTabPanel>
      </>
    );
};

export default AjoutProduit;