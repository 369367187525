import Config from "./Config";
import useRefreshToken from "./useRefreshToken";
// import { useNavigate } from "react-router-dom";

const useAxiosInterceptors = () => {
    // const navigate = useNavigate()
    const refresh = useRefreshToken();
    const axiosInstance = Config()
    const token = localStorage.getItem('accessToken')

    // useEffect(() => {
        axiosInstance.interceptors.request.use((request) => {
            console.log(request.headers.Authorization)
            if (!request.headers.Authorization) {
                request.headers.Authorization = `Bearer ${token}`;
            }
            if (!request.roles) {
                request.roles = "12345"
            }
            return request
        })
            
       axiosInstance.interceptors.response.use(
            response => response,
            async (error) => {
                console.log(error)
                const prevRequest = error?.config;
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    console.log("refresh token: ",newAccessToken)
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken.accessToken}`;
                    prevRequest.roles = newAccessToken.roles
                    return axiosInstance(prevRequest)
                }
                return Promise.reject(error);
            }
        );

        // return () => {
            // axiosInstance.interceptors.request.eject(requestIntercept);
            // axiosInstance.interceptors.response.eject(responseIntercept);
    //     }
    // }, [accessToken, refresh])

    return axiosInstance;
}

export default useAxiosInterceptors;