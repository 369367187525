import { CssBaseline, ThemeProvider } from "@mui/material";
import { themeSettings } from "theme";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "scenes/dashboard/Dashboard";
import Layout from "scenes/layout/Layout";
import {createTheme} from '@mui/material/styles'
import Products from "scenes/products/Products"
import Customers from "scenes/customers/customers"
import Transactions from "scenes/transactions/Transactions"
import Geography from "scenes/geography/Geography"
import Overview from "scenes/overview/Overview"
import Daily from "scenes/daily/Daily"
import Monthly from "scenes/monthly/Monthly"
import Breakdown from "scenes/breakdown/breakdown"
import Admin from "scenes/admin/admin"
import Performance from "scenes/performance/Performance"
import Test from "scenes/test/Test"
import Store from "scenes/store/Store"
import LIVE from "scenes/LIVE/Live"; 
import LogScreen from "scenes/Login/LogScreen";
import RequireAuth from "components/requireAuth";
import ImportExport from "scenes/ImportExport/ImportExport"; 
import AjoutProduit from "scenes/ajoutProduit/AjoutProduit";
import RegisterScreen from "scenes/register/register";


const ROLES = {
  'User': 2001,
  'Editor': 1984,
  'Admin': 5150
}



function App() {

  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(()=> createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme = {theme}>
          {/* <CssBaseline /> */}
          <Routes >
            <Route path = "/" element = {<LogScreen />} />
            <Route path = "/register" element ={<RegisterScreen />} />
            <Route element = {<Layout />}>

            
              {/* <Route path ="/" element ={<Navigate to ="/dashboard" replace />} /> */}

              {/* storeId et userId pour accéder à Dashboard dans state/index*/}
              {/* <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}> */}
                
              {/* </Route> */}
              
              <Route element={<RequireAuth allowedRoles={[ROLES.Editor]} />}>
                
                <Route path = "/products" element ={<Products />} />
                <Route path = "/customers" element = {<Customers />} />
              </Route>
              <Route path = "/dashboard" element = {<Dashboard />} /> 
              <Route path = "/transactions" element = {<Transactions />} />
              <Route path = "/geography" element = {<Geography />} />
              <Route path = "/overview" element = {<Overview />} />
              <Route path = "/daily" element = {<Daily />} />
              <Route path = "/monthly" element = {<Monthly />} />
              <Route path = "/breakdown" element = {<Breakdown />} />
              <Route path = "/admin" element = {<Admin />} />
              <Route path = "/performance" element = {<Performance />} />
              <Route path = "/test" element = {<Test />} />
              <Route path = "/store" element = {<Store />} />
              <Route path = "/live" element = {<LIVE />} />
              <Route path = "/importexport" element ={<ImportExport />} />
              <Route path = "/ajoutproduit" element ={<AjoutProduit />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

