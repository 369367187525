import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetTransactionsQuery } from "state/api";
import Header from "components/Header";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from 'react-redux';

const Transactions = () => {
  const theme = useTheme();

  // values to be sent to the backend
  // const [page, setPage] = useState(0);
  // const [pageSize, setPageSize] = useState(20);
  // const [sort, setSort] = useState({});
  const storeId = useSelector((state) => state.global.storeId);


  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");


  // const { data, isLoading } = useGetTransactionsQuery({
  //   page,
  //   pageSize,
  //   sort: JSON.stringify(sort),
  //   search,
  // });
  const {data, isLoading } = useGetTransactionsQuery({storeId,searchInput})
  console.log(data, search, searchInput)

    const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "prix",
      headerName: "Prix en €",
      flex: 0.5,
      renderCell: (params) => `${params.value}  €`
    },
    {
      field: "date",
      headerName: "Jour",
      flex: 0.5,
      renderCell: (params) => params.value ? `${params.value.substring(5, 10)}` : ''
    },
    {
      field: "qty",
      headerName: "# de produits",
      flex: 0.5,
      // sortable: false,
      // renderCell: (params) => params.value.length,
    },
    // {
    //   field: "cost",
    //   headerName: "Cost",
    //   flex: 1,
    //   renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    // },
    {
      field: "items",
      headerName: "Détail des produits",
      flex: 1.5,
      headerClassName: "no-comma-header",
      valueGetter: (params) => {
        if (params.value) {
          return params.value.join(", ").replace(/,/g, " /");
        }
        return "/";
      },
    },
    {
      field : "validation",
      headerName : "Validation",
      flex: 0.5,
      renderCell: (params) => (
        params.value ? 
          <DoneIcon style={{ 
            color: `${theme.palette.secondary[200]} !important`,
           }} /> :
          <CancelIcon style={{
            color: `${theme.palette.secondary[200]} !important`,
           }} />
      )
    },
    {
      field : "heure",
      headerName : "Heure",
      flex: 0.5
    }
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="TRANSACTIONS" subtitle="Liste complète de toutes les transactions" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            color : theme.palette.secondary.main
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color : theme.palette.secondary.main
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.main,
            // color: theme.palette.secondary[100],
            color : theme.palette.secondary.main,
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.main,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.main,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary.main} !important`,
          },
        }}
      >
      { data ? ( 
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.cursor) || []}
          columns={columns}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        />
      ) : ('')}
        {search}
        {/* <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data.transactions) || []}
          columns={columns}
          rowCount={(data && data.total) || 0}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onSortModelChange={(newSortModel) => setSort(...newSortModel)}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { searchInput, setSearchInput, setSearch },
          }}
        /> */}
      </Box>
    </Box>
  );
};

export default Transactions;

