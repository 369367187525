import { Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';


const RequireAuth = ({ allowedRoles }) => {
    const roles = useSelector((state) => state.global.Roles)

    return (
        roles.includes(allowedRoles)
            ? <Outlet />
            : <div>Vous n'avez pas accès à cette ressource</div>
    );
}

export default RequireAuth;