import React, {useState} from "react";
import {Typography, useTheme} from "@mui/material";
// import { useGetInfoQuery } from "state/api";
import { } from "state/api";
import { useSelector } from 'react-redux';
import { FormControl, MenuItem, InputLabel, Box, Select, Button, ButtonGroup} from '@mui/material';
import Header from 'components/Header';
import OrdersEnCours from "components/DisplayOrders";
import OrdersPayées from "components/DisplayPayées"

const LIVE = () => {

  const [view, setView] = useState("En cours");

  const theme = useTheme();


    
  return (
    <Box m = "1.5rem 2.5rem">
        {/* <Header title ="LIVE" subtitle = "Outil de pilotage" /> */}
        <Box height = "75vh">
          {/* <Typography sx={{marginBottom : "2px", marginLeft : "4rem", color : "black"}}>Paniers</Typography> */}
            <ButtonGroup variant="outlined" color="primary"    
             sx={{
                transitionDuration: '0.01s',
                transitionTimingFunction: 'ease-in-out',
                backgroundColor: "#ffffff",
                border: '1px solid #000000',
                color : theme.palette.secondary.main
              }}>
              <Button
                onClick={() => setView("En cours")}
                variant={view === "En cours" ? "contained" : "outlined"}
                sx={{
                  background: view === "En cours" ? 'linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%)': "",
                  color: view === "En cours" ? "#000000" : "#A9A9A9",
                  fontWeight: view === "En cours" ? "bold" : "normal",
                }}
              >
                En cours
              </Button>
              <Button
                onClick={() => setView("payees")}
                variant={view === "payees" ? "contained" : "outlined"}
                sx={{
                  background: view === "payees" ? 'linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%)': "",
                  color: view === "payees" ? "#000000" : "#A9A9A9",
                  fontWeight: view === "payees" ? "bold" : "normal",
                }}
              >
                Payés
              </Button>
            </ButtonGroup>
            {view === "En cours" ?(
              <div>
                <OrdersEnCours />
              </div>
            ) : (
              <div>
                <OrdersPayées />
              </div>
            )}
        </Box>
    </Box>
);
}

export default LIVE