import axios from 'axios'



const Config = (auth) => {

    // const accessToken = useSelector((state) => state.global.accessToken);
    const Instance = axios.create({
        // baseURL: "http://localhost:80",
        // baseURL: "https://backoffice-rabvfmzrtq-oc.a.run.app",//BACKOFFICE LIVE
        baseURL: "https://sb-backoffice-backofficetest-pm42by52sa-od.a.run.app/", //BACKOFFICE TEST
        withCredentials: true, 
        headers: {
            // Authorization: `Bearer ${accessToken}`
        }

    })
        
    return Instance
};

export default Config;