import React, { useRef, useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import { MenuItem, Typography, Box, useTheme} from '@mui/material';
import { setStore, setUserId, setRoles, setUsername } from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { usePostAuthMutation } from '../../state/api';
import LogPikkoImg from '../../assets/LogPikkoImg.png';
import LogPikkoLogo from '../../assets/LogPikkoLogo.png';
import './styles.css';
import Modal from "../../components/Modal";
import { DotLoader } from "react-spinners";



const LogScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userRef = useRef();
  const theme = useTheme();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [stores, setStores] = useState();

  const store = useSelector((state) => state.global.store);
  const [skip, setSkip] = useState(true);
  const [getLogin, {data}]= usePostAuthMutation();
  const [login, setLogin] = useState(false)
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false);

  const closePopup = () => {
    setOpen(false)
  };

  useEffect(() => {
    data?.accessToken ? localStorage.setItem('accessToken', data.accessToken) : <></>;
    data?.stores ? setStores(data.stores): <></>;
    data?.userId ? dispatch(setUserId(data.userId)) : <></>;
    data?.roles ? dispatch(setRoles(data.roles)) : <></>;
    data?.username ? dispatch(setUsername(data.username)) : <></>;
  }, [data]);

  useEffect(() => {
    userRef.current.focus();
    setLogin(new URLSearchParams(window.location.search).get("ok"));
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  useEffect(() => {
    const previousLocation = sessionStorage.getItem('previousLocation');
    console.log(previousLocation)
  
    if (previousLocation && previousLocation !== location.pathname && location.pathname === '/') {
      console.log('Please log in again');
    }
  
    sessionStorage.setItem('previousLocation', location.pathname);
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSkip(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickMenuItem = (value) => {
    console.log(value)
    dispatch(setStore(value));
  };

  const handleLogIn = async() =>{
    setLoading(true)
    await getLogin(    
      { user, pwd },
      {
        skip: skip,
      })
    setLoading(false)
  }
  useEffect(() => {
    if(store && store._id && store._id.length > 0){
      navigate('/live');
    }
  }, [store])

  return (
    <>
    {/* _______________________POP UP SESSION EXPIREE______________________*/}
        {login && open && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            minHeight: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "clamp(30%, 180px, 50%)",
              minHeight: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 0 0 100vmax rgb(0 0 0/80%)",
              position: "fixed",
              zIndex: 10,
            }}
          >
            <Modal login={login} handleClose={closePopup} />
          </div>
        </div>
      )}




    <div className="container">
      {/* Left Block */}
      <div className="left-block">
        {/* Content for the left block */}
        <div className="left-content">
          <Box
            className ="Logo"
            component="img"
            alt="Logo"
            src={LogPikkoLogo}
            width="10rem"
            height="auto"
            sx={{ objectFit: 'contain' }}
          />
        </div>

        <Box
          className='centered'
          component="img"
          src={LogPikkoImg}
          width="auto"
          height="15rem"
          alt="Centered"
          sx={{
            objectFit: 'cover',
          }}
        />
      </div>

      {/* Right Block */}
      <div className="right-block">
        {/* Content for the right block */}
        {stores ? (
          stores.map((store) => (
            <>
            <MenuItem
            
              key={store._id}
              onClick={() => handleClickMenuItem(store)}
            >
              <Typography className="store-name store-name-background" >{store.name}</Typography>
            </MenuItem>$
            </>
          ))
        ) : (
          <section>
            <div className="right-content">
              <label className="title">Login</label>
              {/* <label className="description">
                We'd love to have you! Join our network of creators and freelancers.
              </label> */}
              <form onSubmit={handleSubmit} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div className="form-item">
                  <label style={{paddingLeft: "10px"}} htmlFor="username">ID/ Utilisateur :</label>
                  <input
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                  />
                </div>

                <div className="form-item">
                  <label style={{paddingLeft: "10px"}} htmlFor="password">Mot de passe :</label>
                  <input
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                  />
                </div>
          {loading ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography sx={{color: theme.palette.secondary.main}}>Chargement...</Typography>
                  <DotLoader
                  loading = {loading}
                  size = {50}
                  color={theme.palette.secondary.main}
               />
          </Box>
                      ) :(<button onClick= {handleLogIn}className="login-button">Connexion</button>)}

              </form>
            </div>
          </section>
        )}
      </div>
    </div>
    </>
  );
};

export default LogScreen;