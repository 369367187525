import React, {useState} from 'react'
import Header from 'components/Header';
import OverviewChart from "components/OverviewChart";
import { FormControl, MenuItem, InputLabel, Box, Select, useTheme} from '@mui/material';


const Overview = () => {

const [view, setView] = useState("Transactions");
const theme = useTheme()

  return (
    <Box m = "1.5rem 2.5rem">
        <Header title ="OVERVIEW" subtitle = "Overview des revenues et bénéfices" />
        <Box height = "75vh">
            <FormControl sx={{ mt: "1rem"}}>
                <InputLabel sx={{
                color : theme.palette.secondary.main
              }}>View</InputLabel>
                <Select value={view} label = "View" onChange={(e) => setView (e.target.value)}>
                <MenuItem value = "Ventes">Ventes</MenuItem>
                <MenuItem value = "Transactions">Transactions</MenuItem>
                </Select>
            </FormControl>
            <OverviewChart view = {view} />
        </Box>
    </Box>
  )
}

export default Overview