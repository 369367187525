import { createSlice } from "@reduxjs/toolkit";
import Config from "../axios/Config";
import axios from "axios";

const initialState = {
  mode: "dark",
  store: localStorage.getItem('store') ? JSON.parse(localStorage.getItem('store')) : undefined,
  userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
  Roles: localStorage.getItem('Roles') ? localStorage.getItem('Roles') : undefined,
  username : localStorage.getItem('username') ? localStorage.getItem('username') : undefined,
  // accessToken: localStorage.getItem('accesstoken') ? localStorage.getItem('accesstoken') : undefined
  // userId: "63701cc1f03239b7f700000e",

  // storeId: "6363caceb139179b73b836fe",
  // storeId : "63b2cab958670bcacfe2231a",
  // storeId: "631e6b8b2f0adb6f5f64e6f3" ACID VIOLETTE

  //storesprodcuts
  // storeId : "63bd747c7cf31b786e195abc"
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setStore: (state, action) => {
      console.log(action.payload)
      localStorage.setItem('store', JSON.stringify(action.payload))
      state.store = action.payload;
    },
    setUserId: (state, action) => {
      localStorage.setItem('userId', action.payload)
      state.userId = action.payload;
    },
    setRoles: (state, action) => {
      localStorage.setItem('Roles', [action.payload])
      state.Roles = action.payload
    },
    setUsername: (state, action) => {
      localStorage.setItem('username', [action.payload])
      state.username = action.payload
    },

    resetStore: (state) => {
      state.store = {}
    },
    resetUserId: (state) => {
      state.userId = {}
    },
    resetRoles: (state) => {
      state.Roles = {}
    },
    resetUsername: (state) => {
      state.username = {}
    },

    // setAccessToken: (state, action) => {
    //   localStorage.setItem('accessToken', action.payload)
    //   state.accessToken = action.payload;
    // },

  },
});

export const { setMode, setStore, setUserId, setRoles, setUsername, resetStore, resetUserId, resetRoles, resetUsername } = globalSlice.actions;

export default globalSlice.reducer;

