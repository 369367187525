import React, {useState, useMemo} from 'react'
import Header from 'components/Header';
import { Box, useTheme} from '@mui/material';
import { useGetSalesQuery } from 'state/api';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import { ResponsiveLine } from "@nivo/line";
import { useSelector } from 'react-redux';
const Daily = () => {
    const [startDate, setStartDate] = useState(new Date("2023-09-24"));
    const [endDate, setEndDate] = useState(new Date("2023-12-30"));
    const storeId = useSelector((state) => state.global.storeId);
    const {data} = useGetSalesQuery(storeId);
    const theme = useTheme();
    console.log(data)

    const [formattedData] = useMemo (()=> {
        if(!data) return [];

        const dailyData = data.dailyData;
        const dailyVentesLine = {
            id: "dailyVentes",
            color : "#29DD51",
            data : []
        };
        const dailyTransactionsLine = {
            id: "dailyTransactions",
            color: theme.palette.primary[600],
            data : []
        };

        Object.values(dailyData).forEach(({date,dailyVentes, dailyTransactions}) => {
            const dateFormatted = new Date(date);
            if (dateFormatted >= startDate && dateFormatted <= endDate){
                const splitDate = date.substring(5, 10);

                dailyVentesLine.data = [
                    ...dailyVentesLine.data,
                    { x: splitDate, y: dailyVentes}
                ];

                dailyTransactionsLine.data = [
                    ...dailyTransactionsLine.data,
                    { x: splitDate, y: dailyTransactions}
                ];
            }
        })
        const formattedData = [dailyVentesLine, dailyTransactionsLine]
        console.log(formattedData)
        return [formattedData];
        
    }, [data, startDate, endDate])

    return (
        <Box m="1.5rem 2.5rem">
          <Header title="Ventes quotidiennes" subtitle="Graphique des ventes par jour" />
          <Box height="75vh">
            <Box display="flex" justifyContent="flex-end">
              <Box>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
              </Box>
              <Box>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
              </Box>
            </Box>
    
            {data ? (
              <ResponsiveLine
                data={formattedData}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: theme.palette.secondary[200],
                      },
                    },
                    legend: {
                      text: {
                        fill: "black",
                      },
                    },
                    ticks: {
                      line: {
                        stroke: theme.palette.secondary[200],
                        strokeWidth: 1,
                      },
                      text: {
                        fill: "black",
                      },
                    },
                  },
                  legends: {
                    text: {
                      fill: theme.palette.secondary[200],
                    },
                  },
                  tooltip: {
                    container: {
                      color: theme.palette.primary.main,
                    },
                  },
                }}
                colors={{ datum: "color" }}
                margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                curve="catmullRom"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 90,
                  legend: "Mois",
                  legendOffset: 60,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Total",
                  legendOffset: -50,
                  legendPosition: "middle",
                }}
                enableGridX={false}
                enableGridY={false}
                pointSize={10}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: 50,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          color: "black",
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            ) : (
              <>Loading...</>
            )}
          </Box>
        </Box>
      );
}

export default Daily