import React, { useEffect, useRef, useState } from "react";
import { useGetProductsMutation } from "state/api";
import { Box, Button, useTheme, Typography, Input} from "@mui/material";
import { DotLoader } from "react-spinners";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useSelector } from "react-redux";
import { notifyError, notifySuccess } from "popup/notify";



const Importcsv = ({ storeId }) => {
  const [getProducts] = useGetProductsMutation();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false)
  const [currentDate, setCurrentDate] = useState()
  const {mutations} = useSelector(state => state.adminApi)
  const theme = useTheme();


    const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("storeId", storeId);
    await getProducts(formData);
   
  };
  function formatTimestamp(timestamp) {
    // Convert milliseconds to seconds (divide by 1000)
    timestamp = Math.floor(timestamp / 1000);
  
    // Create a Date object from the timestamp (in seconds)
    const date = new Date(timestamp * 1000);
  
    // Format the date as "dd/mm/yyyy"
    const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  
    const [{ value: day }, , { value: month }, , { value: year }] = dateFormatter.formatToParts(date);
  
    // Format the time as "hh:mm"
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    const finalDate = `${day}/${month}/${year} ${formattedTime}`
    localStorage.setItem('lastImport', finalDate)
    // Return the formatted date and time
    return finalDate
      
  }
  
  useEffect(() => {
    if(success){
      notifySuccess(
        "Félicitations !", 
        "Le fichier a été chargé avec succès !",
        "OK",
        () => {window.location.reload()}
        )
    }
  }, [success])
  

  
  useEffect(() => {
    if(localStorage.getItem('lastImport')) {
      setCurrentDate(localStorage.getItem('lastImport'))
    }
    for (var key in mutations){
      console.log(key)
      console.log(mutations[key])
      // if(mutations[key].data === 'Objets ajoutés' && mutations[key].status === "fulfilled"){
        if(mutations[key].data){
        setLoading(false);
        setSuccess(true)
        setCurrentDate(formatTimestamp(mutations[key].fulfilledTimeStamp))
      }else if(mutations[key].status === 'rejected'){
        console.log('rejected')
        setLoading(false);
        notifyError(
          "Erreur",
          "Veuillez réessayer, si le problème persiste contactez l'administrateur",
          "OK",
          () => {window.location.reload()}
        )
      }
    }
  }, [mutations]);

  return (
    <>
    {loading ? (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{color: theme.palette.secondary.main}}>Chargement en cours... (jusqu'à 7 minutes max) Ne pas interrompre</Typography>
            <DotLoader
            loading = {loading}
            size = {125}
            color={theme.palette.secondary.main}
           />
      </Box>
    ) :(
      <Box
      sx={{
        textAlign: "center",
        // marginTop: "2rem", 
      }}
    >
      <Button
        variant="contained"
        component="label"
      >
        Upload File
        <input
          type="file"
          hidden
          id="csvFileInput"
          accept=".csv"
          onChange={handleOnChange}
        />
      </Button>
      <h1 style={{ color: "black", marginBottom: "1rem" }}>
        MISE A JOUR DE LA BASE ARTICLES
      </h1>
      <h5 style={{color :"black"}}>Date du dernier import : {currentDate}</h5>
      <div style={{display: "inline-block"}}>
      {/* <h2 style={{color :"red"}}>IMPORTANT</h2><h3 style={{ color: "black"}}>NOMS COLONNES CSV: EAN;Libelle article;Prix de vente;Libelle famille</h3> */}
      </div>
      <form>
      <label htmlFor="csvFileInput">
        <input
          type="file"
          id="csvFileInput"
          accept=".csv"
          onChange={handleOnChange}
          style={{
            color: "black",
            fontSize: "16px",
            padding: "10px 20px",
          }}
        />
      </label>
      
      {file && (
        // <span style={{ color: "green", fontSize : "60px"}}>✔</span>
        <div
        style={{
          display: "inline-block",
          verticalAlign: "middle", // Align it vertically in the middle
        }}
      >
        <KeyboardBackspaceIcon sx={{color : theme.palette.secondary.main, fontSize: "3rem"}}/>
        </div>
      )}
    </form>
        <Button
          variant="contained"
          color="success" 
          size="large" 
          sx={{ marginTop: "3rem" }}
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          IMPORT CSV
        </Button>
        <br />
        {success ? (
          <>
          <Typography
            sx={{
              fontSize: "18px",
              marginTop: "1rem", 
              color: theme.palette.secondary.main,  
            }}
          >
            Le fichier {file.name} a été chargé avec succès !
          </Typography>
          <DoneAllIcon sx={{fontSize : "3em", color :'green'}}/>
          </>
        ): (
          <></>
        )}
    </Box>
    )}
   </>
  );
};

export default Importcsv;
