import React, { useEffect, useState } from "react";
import { useGetStoresQuery } from "state/api";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { setStore } from "state";

const Store = () => {
  const userId = useSelector((state) => state.global.userId);
  const { data, isLoading } = useGetStoresQuery(userId);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (value) => {

    dispatch(setStore(value)); // appel de l'action setSelectedValue avec la valeur sélectionnée
    handleClose();
  };
  useEffect(() => {
    if(data){
      console.log(data)
      alert(data)
    }
    
  }, [data])
  return (
    <div>
      <Button onClick={handleClick}>Open menu</Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {isLoading ? (
          <MenuItem disabled>Loading...</MenuItem>
        ) : data ? (
          data.map((store) => (
            <>
            <MenuItem
              key={store._id}
              onClick={() => handleClickMenuItem(store._id)}
            >
              <Typography>{store.name}</Typography>
            </MenuItem>
            </>
          ))
        ) : (
          <MenuItem disabled>No data found.</MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default Store;