import React from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
} from "@mui/icons-material";
import {  useState } from "react";
import {  useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import LiveTvIcon from '@mui/icons-material/LiveTv';
import InsightsIcon from '@mui/icons-material/Insights';
import LogPikkoLogo from '../assets/LogPikkoLogo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import LogOut from "./LogOut"
import ImportExportIcon from '@mui/icons-material/ImportExport';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlusOneOutlinedIcon from '@mui/icons-material/PlusOneOutlined';
import './Sidebar.css';
import { useSelector } from "react-redux";




const Sidebar = ({
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile
}) => {
  // const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const[subActive, setSubActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const currentStore = useSelector((state) => state.global.store)

  const navItems = [
  {
    text: currentStore.live ? (
      <div>
        LIVE
        <span className="flashing-circle"></span>
      </div>
    ) : (
      <div>
        OFF
      </div>
    ),
    icon: <LiveTvIcon />
    
  },
  {
    text: "ImportExport",
    icon: <ImportExportIcon />
  },
  {
    text: "Ajout Produit",
    icon: <PlusOneOutlinedIcon/>

  }
  // {
  //   text: "Dashboard",
  //   icon: <DashboardIcon />,
  // },
  // {
  //   text: "Analytics",
  //   icon: <InsightsIcon />,
  //   subItems : [  
  //   {
  //     text: "Client Facing",
  //     icon: null,
  //   },
  //   {
  //     text: "Products",
  //     icon: <ShoppingCartOutlined />,
  //   },
  //   {
  //     text: "Customers",
  //     icon: <Groups2Outlined />,
  //   },
  //   {
  //     text: "Transactions",
  //     icon: <ReceiptLongOutlined />,
  //   },
  //   {
  //     text: "Geography",
  //     icon: <PublicOutlined />,
  //   },
  //   {
  //     text: "Sales",
  //     icon: null,
  //   },
  //   {
  //     text: "Overview",
  //     icon: <PointOfSaleOutlined />,
  //   },
  //   {
  //     text: "Daily",
  //     icon: <TodayOutlined />,
  //   },
  //   {
  //     text: "Monthly",
  //     icon: <CalendarMonthOutlined />,
  //   },
  //   {
  //     text: "Breakdown",
  //     icon: <PieChartOutlined />,
  //   },
  //   {
  //     text: "Management",
  //     icon: null,
  //   },
  //   {
  //     text: "Admin",
  //     icon: <AdminPanelSettingsOutlined />,
  //   },
  //   {
  //     text: "Performance",
  //     icon: <TrendingUpOutlined />,
  //   },
  //  ],
  // },
];

// const navItems = [
//   {
//     text: "LIVE",
//     icon: <LiveTvIcon />,
//   },
//   {
//     text: "Analytics",
//     icon: <InsightsIcon />,
//   },  
//     {
//       text: "Client Facing",
//       icon: null,
//     },
//     {
//       text: "Products",
//       icon: <ShoppingCartOutlined />,
//     },
//     {
//       text: "Customers",
//       icon: <Groups2Outlined />,
//     },
//     {
//       text: "Transactions",
//       icon: <ReceiptLongOutlined />,
//     },
//     {
//       text: "Geography",
//       icon: <PublicOutlined />,
//     },
//     {
//       text: "Sales",
//       icon: null,
//     },
//     {
//       text: "Overview",
//       icon: <PointOfSaleOutlined />,
//     },
//     {
//       text: "Daily",
//       icon: <TodayOutlined />,
//     },
//     {
//       text: "Monthly",
//       icon: <CalendarMonthOutlined />,
//     },
//     {
//       text: "Breakdown",
//       icon: <PieChartOutlined />,
//     },
// ];

  // useEffect(() => {
  //   setActive(pathname.substring(1));
  // }, [pathname]);

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              // color: "#0E0B26",
              background: "#0E0B26",
              boxSixing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
              zIndex: 1,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem auto 2rem 1rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" marginLeft="0.5rem">
                  {/* <Typography variant="h4" fontWeight="bold">
                    PIKKOPAY
                  </Typography> */}
                    <Box
                      className ="Logo"
                      component="img"
                      alt="Logo"
                      src={LogPikkoLogo}
                      width="10rem"
                      height="auto"
                      sx={{ objectFit: 'contain' }}
                    />
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, flashingCircle, icon, subItems }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem", color : theme.palette.primary.main}}>
                      {text}
                      {flashingCircle && <span className="flashing-circle"></span>}
                    </Typography>
                  );
                }
                const lcText = typeof text === 'string' ? text.toLowerCase() : 'live';
                // const lcSubText = subItems.text.toLowerCase();

                return (
                  <>
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          if (subItems) {
                            setActive(lcText);
                            if(active === lcText && open === false) {
                              setOpen(true)
                            }
                            if(active === lcText && open === true) {
                              setOpen(false)
                            }
                          } else {
                            navigate(`/${lcText.replace(/\s/g, '')}`);
                            setActive(lcText.replace(/\s/g, ''));
                          }
                        }}
                        sx={{
                          background:
                            active === lcText
                              ? 'linear-gradient(140deg, rgba(225, 255, 38, 1) 15%, rgba(255, 207, 38, 1) 100%)'
                              : "transparent",
                          color:
                            active === lcText
                              ? theme.palette.secondary.main
                              : theme.palette.primary.main,
                          // borderRadius : active === lcText ? "75%" : "0%",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "2rem",
                            color:
                              active === lcText
                                ? theme.palette.secondary.main
                                : theme.palette.primary.main,
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {active === lcText && (
                          <ChevronRightOutlined sx={{ ml: "auto" }} />
                        )}
                      </ListItemButton>
                    </ListItem>


                    {active === lcText && subItems && open && (
                      <List>
                        {subItems.map((subItem) => {
                          if (!subItem.icon) {
                            return (
                              <Typography key={subItem.text} sx={{ m: "2rem 0 1rem 3rem", color : theme.palette.primary.main }}>
                                {subItem.text}
                              </Typography>
                            );
                          }
                          const lcSubText = subItem.text.toLowerCase().replace(/\s/g, "-");
                          return (
                            <ListItem
                              key={subItem.text}
                              disablePadding
                              onClick={() => {
                                navigate(`/${lcSubText}`); 
                                // setActive(lcSubText);
                                setSubActive(lcSubText)
                              }}
                              sx={{
                                backgroundColor:
                                  subActive === lcSubText
                                    ? theme.palette.secondary[300]
                                    : "transparent",
                                color:
                                subActive === lcSubText
                                    ? theme.palette.primary[600]
                                    : theme.palette.secondary[100],
                              }}
                            >
                              <ListItemButton>
                                <ListItemIcon
                                  sx={{
                                    ml: "2rem",
                                    color:
                                    subActive === lcSubText
                                        ? theme.palette.primary[600]
                                        : theme.palette.secondary[200],
                                  }}
                                >
                                  {subItem.icon}
                                </ListItemIcon>
                                <ListItemText primary={subItem.text} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                    )}

                  </>
                );
              })}
            </List>
          </Box>

            <Box
              // position="absolute"
              bottom="0rem"
              width="100%"
              borderTop="1px solid"
              borderColor={theme.palette.secondary[500]}
              py="1.5rem"
            >
              <FlexBetween textTransform="none" gap="1rem" m="0 2rem">
                <LogoutIcon
                  sx={{
                    color: theme.palette.secondary[300],
                    fontSize: "25px",
                  }}
                />
                {/* <Typography sx={{ color: theme.palette.secondary[100] }}>Log out</Typography> */}
                <LogOut />
              </FlexBetween>
            </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;