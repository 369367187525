import React, { useState } from "react";
import {
  LightModeOutlined,
  DarkModeOutlined,
  Menu as MenuIcon,
  Search,
  SettingsOutlined,
  ArrowDropDownOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Button,
  Box,
  Typography,
  IconButton,
  InputBase,
  Toolbar,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useGetStoresQuery } from "state/api";
import { setStore } from "state";
import LogOut from "components/LogOut";
import onOff from '../assets/onOff.svg'
import { notifySuccess, notifyWarning } from "../popup/notify";
import useAxiosInterceptors from "../axios/useAxios";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {

  const axiosInstance = useAxiosInterceptors()
  const dispatch = useDispatch();
  const theme = useTheme();
  const userId = useSelector((state) => state.global.userId);
  const currentStore = useSelector((state) => state.global.store)
  const store = useSelector((state) => state.adminApi.queries)
  const username = useSelector((state) => state.global.username)
  const { data, isLoading } = useGetStoresQuery(userId);
  const [menuType, setMenuType] = useState("stores");
  const status = currentStore.live ? "LIVE" : "OFF"

  const [anchorElstores, setAnchorElstores] = useState(null);
  
  const handleClickStoresMenu = (event) => {
    setAnchorElstores(event.currentTarget)
    setMenuType("stores")
    };
  const handleCloseStoresMenu = () => setAnchorElstores(null)

  const [anchorMenu, setAnchorMenu] = useState(null);
  const handleClickMenu = (event) => {
  setAnchorMenu(event.currentTarget)
  setMenuType("logout")
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null) 
  }

  const handleClickMenuItem = (value) => {
    dispatch(setStore(value)); // appel de l'action setSelectedValue avec la valeur sélectionnée
    handleCloseStoresMenu();
  };

  const onOffFunction = () => {
    
    const text = `Êtes-vous sûr(e) de vouloir passer PikkoPay en ${status} ?`
    notifyWarning(
      'Attention',
      text,
      'Oui',
      () => axiosInstance.put(`/pilotage/stores/${currentStore._id}/onoff`).then((result) => {
        notifySuccess(
          "Success", 
          `Le magasin est passé en ${status}`,
          'OK',
          () => {
            dispatch(setStore(result.data.store))
          }
        )
      })
    );
  }

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton sx={{ color: "#000000" }} onClick={() => setIsSidebarOpen(!isSidebarOpen)} >
            <MenuIcon />
          </IconButton>
          {/* <FlexBetween
            backgroundColor={"#F3F1E7"}
            borderRadius="9px"
            // gap="3rem"
            p="0.1rem 2.5rem 0.1rem 2.5rem"
            marginLeft="3rem"
          > */}
            {/* ________________BARRE DE RECHERCHE ________________*/}
            {/* <InputBase sx={{
              color: "#000000", 
              "&::placeholder": {
              color: "#000000", 
              opacity: 1, 
                },
              }} placeholder="Search..." />
            <IconButton color = {"000000"}>
              <Search sx={{ color: "#000000" }} />
            </IconButton> */}
            {/*_____________________________________________________*/}
        <Button sx={{ backgroundColor: "beige", color: "black"}} onClick={handleClickStoresMenu}>Changer de magasin</Button>
        {/* <FlexBetween> */}
        {menuType === "stores" && (
          <Menu anchorEl={anchorElstores} open={Boolean(anchorElstores)} onClose={handleCloseStoresMenu} 
          PaperProps={{
            style: {
              position: 'absolute',
              // width: 'auto',
              // display: 'flex',
              // flexDirection: 'column',
              // alignItems: 'center',
              backgroundColor : theme.palette.primary
            },
          }}> 
            {isLoading ? (
              <MenuItem disabled>Loading...</MenuItem>
            ) : data ? (
              data.map((store) => (
                <MenuItem
                  key={store._id}
                  onClick={() => handleClickMenuItem(store._id)}
                >
                  <Typography>{store.name}</Typography>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No data found.</MenuItem>
            )}
          </Menu>
        )}
        
      </FlexBetween>
      {/* </FlexBetween> */}
      {/* </FlexBetween> */}


           {/* CENTER */}
      {data && data.map((store) => {
        if (currentStore === store._id) {
          return (
            <Typography variant="h3"
            sx={{ color: "#000000", fontWeight: "bold", textAlign: "center" }} key={store._id}>{store.name}</Typography>
          );
        }
        return null;
      })}

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
        <div style={{display: "flex", alignItems: 'center'}}>
          <span style={{ color: 'black'}}>STATUT: </span>
          <span style={{ fontWeight: 'bold', fontSize: '2em', color: status === 'LIVE' ? 'green' : 'red' }}> {status}</span>
        </div>


        <button style={{backgroundColor: "white", border: "none"}} onClick={() => onOffFunction()}>
          <img src={onOff} alt="On/Off" style={{height: "30px", width: "auto"}} />
        </button>
          {/* MODE JOUR / NUIT */}
          {/* <IconButton sx={{ color: "#000000" }} onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton> */}

          {/*____________________SETTINGS BUTTON________________________}
            <IconButton sx={{ color: "#000000" }}>
            <SettingsOutlined sx={{ fontSize: "25px" }} />
          </IconButton> */}

          <FlexBetween>
            <Button
              onClick={handleClickMenu}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
                color: "#000000"
              }}
            >
              <Box
                component="img"
                alt="profile"
                src={currentStore.logo}
                height="32px"
                width="auto"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[500] }}
                >
                  {username}
                </Typography> 
                <Typography
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.occupation}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>
            {menuType === "logout" && (
                <Menu
                  anchorEl={anchorMenu}
                  open={Boolean(anchorMenu)} 
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  {/* <MenuItem onClick={handleLogOut}>Log Out</MenuItem> */}
                  <LogOut isNavBar ={true} />
                </Menu>
              )}
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
      <Box borderBottom="1px solid #C4C4C4" />
    </AppBar>
  );
};

export default Navbar;