import React from "react";
import {useTheme,} from "@mui/material";
// import { useGetInfoQuery } from "state/api";
import { useGetInfoQuery} from "state/api";
import MAJ from "components/MAJ"
import { useSelector } from 'react-redux';

const Test = () => {

  const storeId = useSelector((state) => state.global.storeId);
  const {data, isLoading} = useGetInfoQuery(storeId)
  console.log(data)


  const theme = useTheme();
 

    
  return (
    <div>
      Maj effectuée sur {storeId}
    </div>
    // <div> 
    //   {data ? <MAJ data = {data}></MAJ> : (console.log(""))}
    // </div>
);
}

export default Test