import React, { useState } from "react";
import { useSelector } from "react-redux";
import Importcsv from "components/Importcsv";
import Exportcsv from "components/Exportcsv";
import {Box, Typography, Button, Tabs, Tab, useTheme} from '@mui/material';
import tutoImportExport from '../../assets/tutoImportExport.png';

const ImportExport = () => {
   
  const storeId = useSelector((state) => state.global.store._id);
  const [displayExport, setDisplayExport] = useState(false)
  const [value, setValue] = useState(0)
  const theme = useTheme();


  function CustomTabPanel(props) {
    const { children, value, index } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        color="black"
        style={{color:"black"}}
      >
        {value === index && (
          <Box sx={{ p: 3 , color: "black"}}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (tbh) => {
    setValue(tbh);
  };

  return ( 
    <>
    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
      <Tabs value={value}
      aria-label="basic tabs example" >
        <Tab label="EXPORT PRODUITS" 
        sx={{color: "black"}}
        onClick={() => setValue(0)}
        {...a11yProps(0)} 
        />
        <Tab label="IMPORT BASE ARTICLE"  onClick={() => setValue(1)} sx={{color: "black"}}
        {...a11yProps(1)} 
        />
      </Tabs>
    </Box>

    <CustomTabPanel value={value} index={0} >
      <Exportcsv storeId={storeId} />

    </CustomTabPanel>
    <CustomTabPanel value={value} index={1}>
      <Importcsv storeId={storeId} />


    </CustomTabPanel>


       
        
  {/* </Box> */}
  {/* <Box  sx = {{margin: "10rem"}}>
    <Typography sx={{textAlign: "center", fontSize: "24px", color: "black"}}>
    Mode de fonctionnement
  </Typography>
  <Box
            className ="Logo"
            component="img"
            alt="Logo"
            src={tutoImportExport}
            width="70rem"
            height="auto"
            sx={{ objectFit: 'contain',
            margin: 'auto',
            display: "block", 
            }}
          />
  </Box> */}

  </>

);
};

export default ImportExport;