import Config from './Config';

const useRefreshToken = () => {
    const axiosInstance = Config()

    const refresh = async () => {
        const response = await axiosInstance.get('/refresh', {
            withCredentials: true,
        });
        localStorage.setItem('accessToken', response.data.accessToken)
        
        return response.data;
    }
    return refresh;
};

export default useRefreshToken;