import React, {useMemo} from 'react'
import {ResponsiveLine} from "@nivo/line";
import { useTheme } from '@mui/material';
import { useGetSalesQuery } from 'state/api';
import { useSelector } from 'react-redux';


const OverviewChart = ({ isDashboard = false, view }) => {
    const storeId = useSelector((state) => state.global.storeId);
    const theme = useTheme();
    const {data, isLoading} = useGetSalesQuery(storeId);

    const[monthlyVentesLine, monthlyTransactionsLine] = useMemo(()=> {
        if(!data) return [];

        const monthlyData = data.monthlyData;
        const monthlyVentesLine = {
            id: "monthlyVentes",
            colors : theme.palette.secondary.main,
            data : []
        };
        const monthlyTransactionsLine = {
            id: "monthlyTransactions",
            colors : theme.palette.secondary.main,
            data : []
        };


        Object.values(monthlyData).reduce(
          (acc, { month, monthlyVentes, monthlyTransactions }) => {
            const curVentes = acc.Ventes + monthlyVentes;
            const curTransactions = acc.Transactions + monthlyTransactions;
        
            monthlyVentesLine.data = [
              ...monthlyVentesLine.data,
              { x: month, y: curVentes }
            ];
        
            monthlyTransactionsLine.data = [
              ...monthlyTransactionsLine.data,
              { x: month, y: curTransactions }
            ];
        
            return { Ventes: curVentes, Transactions: curTransactions };
          },
          { Ventes: 0, Transactions: 0 }
        );


        return [[monthlyVentesLine], [monthlyTransactionsLine]];
    },[data]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!data || isLoading) return "Loading...";
    
  return (    <ResponsiveLine
    data={view === "Ventes" ? monthlyVentesLine : monthlyTransactionsLine}
    theme={{
      axis: {
        domain: {
          line: {
            stroke: theme.palette.secondary.main,
          },
        },
        legend: {
          text: {
            fill: theme.palette.secondary.main,
          },
        },
        ticks: {
          line: {
            stroke: theme.palette.secondary.main,
            strokeWidth: 1,
          },
          text: {
            fill: theme.palette.secondary.main,
          },
        },
      },
      legends: {
        text: {
          fill: theme.palette.secondary.main,
        },
      },
      tooltip: {
        container: {
          color: theme.palette.secondary.main,
        },
      },
    }}
    margin={{ top: 20, right: 50, bottom: 50, left: 70 }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: false,
      reverse: false,
    }}
    yFormat=" >-.2f"
    curve="catmullRom"
    enableArea={isDashboard}
    areaBaselineValue = {11}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      format: (v) => {
        if (isDashboard) return v.slice(0, 3);
        return v;
      },
      orient: "bottom",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: isDashboard ? "" : "Mois",
      legendOffset: 36,
      legendPosition: "middle",
    }}
    axisLeft={{
      orient: "left",
      tickValues: 5,
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: isDashboard
        ? ""
        : `Total de ${view === "Ventes" ? "Revenue" : "Transactions"} sur l'année`,
      legendOffset: -60,
      legendPosition: "middle",
    }}
    enableGridX={false}
    enableGridY={false}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabelYOffset={-12}
    useMesh={true}
    legends={
      !isDashboard
        ? [
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 30,
              translateY: -40,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]
        : undefined
    }
  />
  )
}

export default OverviewChart