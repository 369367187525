import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  DownloadOutlined,
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,

} from "@mui/icons-material";
import SellIcon from "@mui/icons-material/Sell"
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import { useGetDashboardQuery } from "state/api";
import StatBox from "components/StatBox";
import { useSelector } from 'react-redux';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import socketIOClient from 'socket.io-client';


const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const storeId = useSelector((state) => state.global.storeId);
  const {data, isLoading, error, isSuccess} = useGetDashboardQuery(storeId)
  const [dashboardData, setDashboardData] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "prix",
      headerName: "Prix en €",
      flex: 0.5,
      renderCell: (params) => `${params.value}  €`
    },
    {
      field: "date",
      headerName: "Jour",
      flex: 0.5,
      renderCell: (params) => params.value ? `${params.value.substring(5, 10)}` : ''
    },
    {
      field: "qty",
      headerName: "# de produits",
      flex: 0.5,
    },
    {
      field : "items",
      headerName : "Détail des produits",
      flex: 1.5,
    },
    // {
    //   field : "validation",
    //   headerName : "Validation",
    //   flex: 0.5,
    //   renderCell: (params) => (
    //     params.value ? 
    //       <DoneIcon style={{ 
    //         color: `${theme.palette.secondary[200]} !important`,
    //        }} /> :
    //       <CancelIcon style={{
    //         color: `${theme.palette.secondary[200]} !important`,
    //        }} />
    //   )
    // },
    {
      field : "heure",
      headerName : "Heure",
      flex: 0.5
    }
  ];

  useEffect(() => {

    if(data)
    {
      setDashboardData(data)
      console.log(dashboardData)
    }
  },[data])


  // ___________________________________________________________TEMPS REEL DASHBOARD________________________________________________

  //   useEffect(() => {

  //   console.log(dashboardData)
    
  //   const socket = socketIOClient('http://localhost:80'); 
  //   if(error && !data){
  //     // data = error
  //     console.log(error)
  //   }
  //   socket.on('connect', () => {
  //     console.log('Connected to the Socket.IO server');
  //     setIsConnected(true);
  //   });

  //   socket.on('disconnect', () => {
  //     console.log('Disconnected from the Socket.IO server');
  //     setIsConnected(false);
  //   });

    
  //   socket.on('OverallChanged', (change) => {
  //     // Update the dashboardData state with the updated data
  //     setDashboardData((prevDashboardData) => {
  //       // Deep clone the previous data to avoid mutation
  //       const updatedData = JSON.parse(JSON.stringify(prevDashboardData));
  //       console.log(updatedData)

  //       // Traverse the updatedFields object
  //       Object.entries(change.updateDescription.updatedFields).forEach(([fieldPath, value]) => {
  //         // Split the fieldPath into an array of nested keys
  //         const keys = fieldPath.split('.');
  //         console.log(keys)

  //         // Update the corresponding field in the updatedData object
  //         let currentObj = updatedData;
  //         keys.forEach((key, index) => {
  //           if (index === keys.length - 1) {
  //             // Update the leaf field
  //             currentObj[key] = value;
  //           } else {
  //             // Traverse deeper into nested objects
  //             currentObj = currentObj[key];
  //           }
  //         })
  //         console.log(currentObj);
  //       });

  //       return updatedData;
  //     });
  //   });
    
  //   return () => {
  //     socket.disconnect();
  //   };
    
    
  // }, [data, error, isSuccess, dashboardData])

  // ________________________________________________________________________________________________________________________________________
  return (
  <>
  {(data) ? (
    <>

       <Box m="1.5rem 2.5rem">
      {/*<FlexBetween>
        <Header title="DASHBOARD" subtitle="Vue d'ensemble" />
        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </FlexBetween> */}
  
      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title="Panier moyen"
          value={dashboardData && `${dashboardData.paniermoyen}  €`} 
          increase=""
          description=""
          icon={
            <ShoppingBasketIcon
              sx={{ color: theme.palette.secondary[700], fontSize: "26px" }}
            />
          } 
          background= "linear-gradient(#BEF5C2, #E4F9DA)"
        />
        {/* if(data.todayStats.dailyTransactions)
          {data.todayStats.dailyTransactions}
          else {
            "0"
          } */}
        <StatBox
          title="Transactions d'aujourd'hui"
          value={(dashboardData?.todayStats?.dailyTransactions ?? 0)}
          increase={(dashboardData?.todayStats?.dailyCA ?? 0) + " €"}
          description="En chiffre d'affaires"
          icon={
            <PointOfSale
              sx={{ color: theme.palette.secondary[700], fontSize: "26px" }}
            />
          }
          background = "linear-gradient(#BCF3DA, #DCFEEF)"
        /> 
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor="#FFFFFF"
          p="1rem"
          sx = {{borderRadius : "1rem",
          border: "0.1rem solid #F0EAEA"
          }}
        >
          <OverviewChart view="sales" isDashboard={true} />
        </Box>
        <StatBox
          title="Temps moyen en magasin"
          value={dashboardData && dashboardData.tempsmoyen}
          increase=""
          description=""
          icon={
            <AccessTimeFilledIcon
              sx={{ color: theme.palette.secondary[700], fontSize: "26px" }}
            />
          }
          background = "linear-gradient(#BCEDF3, #DCFBFE)"
        />
        <StatBox
          title="Ventes sur l'année"
          value={dashboardData && dashboardData.yearlyData[0].yearlyVentes}
          increase=""
          description="Articles vendus"
          icon={
            <SellIcon
              sx={{ color: theme.palette.secondary[700], fontSize: "26px" }}
            />
          }
          background = "linear-gradient(#BBD5F3, #DCFBFE)"
        />
  
        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              color : theme.palette.secondary.main,
              borderRadius : "5rem",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              color : theme.palette.secondary.main,
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.primary[100],
              color: theme.palette.secondary.main,
              borderBottom: "none",
              borderTopLeftRadius :"2rem",
              // borderTopLeftRadius :"2rem"
              borderTopRightRadius : "2rem",
              border: `0.1rem solid ${theme.palette.secondary[300]}`,
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary[100],
              // backgroundColor : "#FFFFFF"
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.main,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary.main} !important`,
            },
          }}
        >
          <DataGrid
            loading={isLoading || !data}
            getRowId={(row) => row._id}
            rows={(dashboardData && dashboardData.transactions) || []}
            columns={columns}
          />
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor="#FFFFFF"
          p="1.5rem"
          sx = {{borderRadius : "1rem",
          border: "0.1rem solid #F0EAEA"
         }}
        >
          <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}>
            Ventes par Catégories
          </Typography>
          <BreakdownChart isDashboard={true} />
          <Typography
            p="0 0.6rem"
            fontSize="0.8rem"
            sx={{ color: theme.palette.secondary[200] }}
          >
            Breakdown of real states and information via category for revenue
            made for this year and total sales.
          </Typography>
        </Box>
      </Box>
    </Box>
    {/* // ) : (
    //   <div>
    //     Aucune donnée à afficher
    //   </div>
    // )} */}
   </>
  ): (<div>
    Erreur
  </div>)}
  </>
);
}

export default Dashboard