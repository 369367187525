import React, { useState } from 'react';
import { FormControl, OutlinedInput, Button } from '@mui/material';

const AddProduct = () => {
    const [formData, setFormData] = useState({
        name: '',
        barcode: '',
        price: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(e.target.name)
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        console.log(formData);
    };

    return (
        <>
        
        <table style={{display: "flex", flexDirection: "column", gap: "20px"}}>
            <FormControl style={{display: "flex", flexDirection:"row", alignItems: "center", gap: "15px"}}>
                <p style={{width: "100px"}}>Nom du produit</p>
                <OutlinedInput
                    style={{ width: '25ch', border: '2px solid black', color: 'black' }}
                    placeholder="Product Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
            </FormControl>
            <FormControl style={{display: "flex", flexDirection:"row", alignItems: "center", gap: "15px"}}>
                <p style={{width: "100px"}}>Code-Barre</p>
                <OutlinedInput
                    style={{ width: '25ch', border: '2px solid black', color: 'black' }}
                    placeholder="Barcode"
                    name="barcode"
                    value={formData.barcode}
                    onChange={handleChange}
                />
            </FormControl>
            <FormControl style={{display: "flex", flexDirection:"row", alignItems: "center", gap: "15px"}}>
                <p style={{width: "100px"}}>Prix</p>
                <OutlinedInput
                    style={{ width: '25ch', border: '2px solid black', color: 'black' }}
                    placeholder="Price"
                    name="price"
                    type="number"
                    value={formData.price}
                    onChange={handleChange}
                />
            </FormControl>
            <Button variant="contained" onClick={handleSubmit}>
                Add Product
            </Button>
        </table>
        </>
    );
};

export default AddProduct;
