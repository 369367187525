import React, {useState} from "react";
import {useSelector } from "react-redux";
import {MenuItem} from "@mui/material";
// import { useGetLogoutQuery } from "state/api";
import { useGetLogoutMutation} from "state/api";
import { useNavigate } from "react-router-dom";
import { resetStore, resetUserId, resetUsername, resetRoles } from "state";
import { useDispatch } from "react-redux";

const LogOut = ({isNavBar = false}) => {
  const userId = useSelector((state) => state.global.userId);
  const store = useSelector((state) => state.global.store);
  // const [logoutClicked, setLogoutClicked] = useState(false);
  const [getLogout] = useGetLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
 


  const handleLogOut = () => {
        // setLogoutClicked(true);
        getLogout(userId);
        try {
          localStorage.clear();
          console.log(store)
          // localStorage.removeItem('accessToken');
          dispatch(resetStore())
          dispatch(resetUserId())
          dispatch(resetUsername())
          dispatch(resetRoles())
          navigate("/");
        } catch (error) {
          console.error("Navigation error:", error);
        }
        
  };

  return (
    <>
      {isNavBar ? (
        <MenuItem onClick={handleLogOut}>Déconnexion</MenuItem>
      ) : (
        <>
          <button style={{
            backgroundColor: "transparent",
            backgroundRepeat: "no-repeat",
            border: "none",
            cursor: "pointer",
            overflow: "hidden",
            outline: "none",
            color: "white"
            }} 
            onClick={handleLogOut}>
            Déconnexion
          </button>
        </>
      )}


    </>
  );  
};

export default LogOut;