import Swal from 'sweetalert2';

export const notifyInfo = (title) => {
  Swal.fire({
    title,
    icon: 'info',
    showCancelButton: false,
    confirmButtonText: `Ok`,
    buttonsStyling: false,
    customClass: {
        confirmButton: 'custom-confirm-button',
    }
  });
};

export const notifyError = (title, text, buttonLabel,callBack) => {
  Swal.fire({
    title,
    text,
    icon: 'error',
    closeButtonHtml: 'X',
    confirmButtonColor: '#FFD700',
    confirmButtonText: 'Ok',
    buttonsStyling: false,
    customClass: {
      title: 'title-class',
      confirmButton: 'custom-confirm-button',
    }
  }).then(() => {
    if (callBack) {
      callBack();
    }
  });
};

export const notifyWarning = (title, text, buttonLabel, callBack) => {
    Swal.fire({
        title,
        text,
        icon: 'warning',
        confirmButtonText: buttonLabel,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui ! ",
        cancelButtonText: "Non"
      }).then((result) => {
        if (result.isConfirmed && callBack) {
          callBack();
        }
      });
   
  };
  

export const notifySuccess = (title, text, buttonLabel, callBack) => {
  Swal.fire({
    title,
    text,
    icon: 'success',
    showConfirmButton: true,
    confirmButtonText: buttonLabel,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'custom-confirm-button',
    },
  }).then(() => {
    if (callBack) {
      callBack();
    }
  });
};
